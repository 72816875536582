import _ from 'lodash';
// import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';

import request from './request-public';
import { translation } from './translate-public';
import LoginPage from './components/login.jsx';

import 'bootstrap3/less/bootstrap.less';
import './styles/login.scss';

function renderRoot(globalSettings) {
  return <LoginPage isMiniVersion={globalSettings.isMiniVersion} />;
}

async function initTranslations(defaultLanguage) {
  const language = defaultLanguage;

  try {
    await translation.initLanguage(language);
  } catch (err) {
    console.error(`Failed to initialize language ${language}`, err);
    await translation.initLanguage(translation.fallbackLanguage);
  }
}

async function start() {
  const { data: globalSettings } = await request.get('/global-settings');
  await initTranslations(_.get(globalSettings, 'ticketDetails.languages[0]'));

  ReactDOM.render(renderRoot(globalSettings), document.getElementById('app'));
}

start();
